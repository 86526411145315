/**
 * App config
 */
export default {
    // General
    VERSION: process.env.VERSION,
    BUILD: process.env.BUILD,
    ENVIRONMENT: '',
    ROOT: '/',
    BASE_URL: process.env.BASE_URL,
    LOCALE: process.env.LOCALE || 'en',
    DEBUG_POPOVER: false,
    CREATE_FAKE_OFFERS: false,
    TEMP_DISABLE_PLAYBACK: process.env.TEMP_DISABLE_PLAYBACK
        && process.env.TEMP_DISABLE_PLAYBACK === 'true',
    TEMP_DISABLE_MEDIA_ITEM_POPOVER: process.env.TEMP_DISABLE_MEDIA_ITEM_POPOVER
        && process.env.TEMP_DISABLE_MEDIA_ITEM_POPOVER === 'true',
    TEMP_DISABLE_CORNER_RIBBON: process.env.TEMP_DISABLE_CORNER_RIBBON
        && process.env.TEMP_DISABLE_CORNER_RIBBON === 'true',

    // Services: Azure Media Player
    AZURE_MEDIA_PLAYER_CDN_URL: `//amp.azure.net/libs/amp/2.2.2/azuremediaplayer.min.js`,

    // Services: Google
    GA_TRACKING_ID: process.env.GA_TRACKING_ID,
    RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,

    // Services: WLS Configuration API
    WLS_CONFIGURATION_API_URI: process.env.VUBIQUITY_WLS_CONFIGURATION_API_URI,

    // Services: SMP Catalogue API
    SMP_CATALOGUE_API_URI: process.env.VUBIQUITY_SMP_CATALOGUE_API_URI,
    SMP_CATALOGUE_API_PROVIDER_ID: process.env.VUBIQUITY_SMP_CATALOGUE_API_PROVIDER_ID,

    // Services: SMP Product API
    SMP_PRODUCT_API_URI: process.env.VUBIQUITY_SMP_PRODUCT_API_URI,

    // Services: SMP Search API
    SMP_SEARCH_API_URI: process.env.VUBIQUITY_SMP_SEARCH_API_URI,
    SMP_SEARCH_API_KEY: process.env.VUBIQUITY_SMP_SEARCH_API_KEY,
    SMP_SEARCH_API_VERSION: process.env.VUBIQUITY_SMP_SEARCH_API_VERSION,

    // Services: OpenID Connect API
    OPEN_ID_API_URI: process.env.VUBIQUITY_OPEN_ID_API_URI,
    OPEN_ID_API_CLIENT_ID: process.env.VUBIQUITY_OPEN_ID_API_CLIENT_ID,
    OPEN_ID_API_CLIENT_SECRET: process.env.VUBIQUITY_OPEN_ID_API_CLIENT_SECRET,

    // Services: SMP Account API
    SMP_ACCOUNT_API_URI: process.env.VUBIQUITY_SMP_ACCOUNT_API_URI,

    // Services: SMP Transaction API
    SMP_TRANSACTION_API_URI: process.env.VUBIQUITY_SMP_TRANSACTION_API_URI,

    // Services: SMP Player Entitlement API
    SMP_PLAYER_ENTITLEMENT_API_URI: process.env.VUBIQUITY_SMP_PLAYER_ENTITLEMENT_API_URI,

    // Services: SMP Identity API
    CC_IDENTITY_API_URI: process.env.VUBIQUITY_CC_IDENTITY_API_URI,
    CC_IDENTITY_API_CLIENT_ID: process.env.VUBIQUITY_CC_IDENTITY_API_CLIENT_ID,
    CC_IDENTITY_API_CLIENT_SECRET: process.env.VUBIQUITY_CC_IDENTITY_API_CLIENT_SECRET,
    UXP_IDENTITY_URI: process.env.VUBIQUITY_UXP_IDENTITY_URI,
    UXP_ACCOUNT_URI: process.env.VUBIQUITY_UXP_ACCOUNT_URI,

    // Services: CR Metadata API
    CR_METADATA_API_URI: process.env.VUBIQUITY_CR_METADATA_API_URI,
};
