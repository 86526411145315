import store from 'store';
import ua from 'universal-analytics';
import uuidGenerator from 'uuid';
import WLSConfigurationService from '@comrock/vub-wls-configuration-service';
import AuthService from '@comrock/vub-wls-auth-service';
import MediaService from '@comrock/vub-wls-media-service';
import MediaEntitlementService from '@comrock/vub-wls-media-entitlement-service';
import LiveStreamingService from '@comrock/vub-wls-live-streaming-service';
// import AuthProviderOIDC from '@comrock/vub-wls-auth-provider-oidc';
// import MediaProviderSMPStateful from '@comrock/vub-wls-media-provider-smp-stateful';
// import MediaEntitlementProviderSMPTransactional from '@comrock/vub-wls-media-entitlement-provider-smp-transactional';
import AuthProviderSMP from '@comrock/vub-wls-auth-provider-smp';
import MediaProviderSMPInMemory from '@comrock/vub-wls-media-provider-smp-in-memory';
import MediaEntitlementProviderSMPInMemory from '@comrock/vub-wls-media-entitlement-provider-smp-in-memory';
import LiveStreamingProviderCRMetadata from '@comrock/vub-wls-live-streaming-provider-cr-metadata';
import CustomMediaRouteResolver from './navigation/CustomMediaRouteResolver';
import config from './config';

export const wlsConfigurationService = new WLSConfigurationService({
    serviceURL: config.WLS_CONFIGURATION_API_URI,
});

const mediaProvider = new MediaProviderSMPInMemory({
    smpCatalogueAPI: {
        serviceURL: config.SMP_CATALOGUE_API_URI,
        providerId: config.SMP_CATALOGUE_API_PROVIDER_ID,
    },
    smpProductAPI: {
        serviceURL: config.SMP_PRODUCT_API_URI,
    },
    smpSearchAPI: {
        serviceURL: config.SMP_SEARCH_API_URI,
    },
});

// const mediaProvider = new MediaProviderSMPStateful({
//     smpCatalogueAPI: {
//         serviceURL: config.SMP_CATALOGUE_API_URI,
//         providerId: config.SMP_CATALOGUE_API_PROVIDER_ID,
//     },
//     smpProductAPI: {
//         serviceURL: config.SMP_PRODUCT_API_URI,
//     },
//     smpSearchAPI: {
//         serviceURL: config.SMP_SEARCH_API_URI,
//     },
// });

export const mediaService = new MediaService(mediaProvider);

// const authProvider = new AuthProviderOIDC({
//     serviceURL: config.OPEN_ID_API_URI,
//     clientId: config.OPEN_ID_API_CLIENT_ID,
//     clientSecret: config.OPEN_ID_API_CLIENT_SECRET,
// });
//
// const mediaEntitlementProvider = new MediaEntitlementProviderSMPTransactional({
//     smpAccountAPI: {
//         serviceURL: config.SMP_ACCOUNT_API_URI,
//     },
//     smpTransactionAPI: {
//         serviceURL: config.SMP_TRANSACTION_API_URI,
//         providerId: config.SMP_CATALOGUE_API_PROVIDER_ID,
//     },
//     smpSearchAPI: {
//         serviceURL: config.SMP_SEARCH_API_URI,
//     },
//     smpCatalogueAPI: {
//         serviceURL: config.SMP_CATALOGUE_API_URI,
//         providerId: config.SMP_CATALOGUE_API_PROVIDER_ID,
//     },
//     smpProductAPI: {
//         serviceURL: config.SMP_PRODUCT_API_URI,
//     },
//     smpPlayerEntitlementAPI: {
//         serviceURL: config.SMP_PLAYER_ENTITLEMENT_API_URI,
//     },
// });

const authProvider = new AuthProviderSMP({
    ccIdentityAPI: {
        serviceURL: config.CC_IDENTITY_API_URI,
        clientId: config.CC_IDENTITY_API_CLIENT_ID,
        clientSecret: config.CC_IDENTITY_API_CLIENT_SECRET,
    },
    uxpIdentityService: {
        identityServiceURL: config.UXP_IDENTITY_URI,
        accountServiceURL: config.UXP_ACCOUNT_URI,
    },
    smpAccountAPI: {
        serviceURL: config.SMP_ACCOUNT_API_URI,
    },
});

const mediaEntitlementProvider = new MediaEntitlementProviderSMPInMemory({
    mediaProviderSMPInMemory: mediaProvider,
    smpPlayerEntitlementAPI: {
        serviceURL: config.SMP_PLAYER_ENTITLEMENT_API_URI,
    },
    smpCatalogueAPI: {
        serviceURL: config.SMP_CATALOGUE_API_URI,
    },
});

export const authService = new AuthService(authProvider);

export const mediaEntitlementService = new MediaEntitlementService(mediaEntitlementProvider);

const liveStreamingProvider = new LiveStreamingProviderCRMetadata({
    crMetadataAPI: {
        serviceURL: config.CR_METADATA_API_URI,
    },
});

export const liveStreamingService = new LiveStreamingService(liveStreamingProvider);

const gaCID = store.get('ga_cid') || uuidGenerator.v4();
store.set('ga_cid', gaCID);
export const gaService = ua(config.GA_TRACKING_ID, gaCID, {https: true});

export const mediaRouteResolver = new CustomMediaRouteResolver();
